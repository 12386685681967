import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  FormGroup,
  Input,
  Button,
} from "reactstrap"
import useWeatherDataQuery from "store/WeatherData/useWeatherDataQuery"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import PaginationComponent from "components/Pagination"
import { exportWeatherDataCSV, exportWeatherDataPdf } from "store/WeatherData/weatherDataUrls"

const WeatherReport = () => {
  const [deviceId, setDeviceId] = useState("DFWSA1015")
  const [page, setPage] = useState(0);
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  const currentDateInYYYYMMDD = yyyy + "-" + mm + "-" + dd

  const [printLimit, setPrintLimit] = useState(10);
  const [startDate, setStartDate] = useState(currentDateInYYYYMMDD);
  const [endDate, setEndDate] = useState(currentDateInYYYYMMDD);

  const query = {
    limit: printLimit,
    offset: printLimit * page,
    deviceId
  }
  const {
    data: weatherData,
    isSuccess,
    refetch,
  } = useWeatherDataQuery.weatherData(query)

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, 3600000)
    return () => clearInterval(interval)
  }, []);

  const [data, setData] = useState({
    columns: [],
    rows: [],
  })

  const convert24To12 = time => {
    if (!time) return "--"
    const [sHours, minutes] = time.split(":")
    const hours = +sHours % 12 || 12
    const amPm = +sHours < 12 ? "AM" : "PM"
    return `${hours}:${minutes} ${amPm}`
  }

  const roundOff = num => {
    if (!num) return 0
    return num.toFixed(2)
  }
  const convertToKph = speed => {
    if (!speed && speed === 0) return 0
    return speed * 3.6
  }

  const getDirection = (angle) => {
    switch(angle) {
      case 0:
        return "North"
      case 1:
        return "North-North East"
      case 2:
        return "North East"
      case 3:
        return "East-North East"
      case 4:
        return "East"
      case 5:
        return "East-South East"
      case 6:
        return "South East"
      case 7:
        return "South-South East"
      case 8:
        return "South"
      case 9:
        return "South-South West"
      case 10:
        return "South West"
      case 11:
        return "West-South West"
      case 12:
        return "West"
      case 13:
        return "West-North West"
      case 14:
        return "North West"
      case 15:
        return "North-North West"
      case 16:
        return "North"
      default:
        return ""

    }

  }

  useEffect(() => {
    if (isSuccess) {
      const columns = [
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 150,
        },
        {
          label: "Time",
          field: "time",
          sort: "asc",
          width: 270,
        },
        {
          label: "Rain Fall (mm)",
          field: "rainFall",
          sort: "asc",
          width: 100,
        },
        {
          label: "Temperature (°C)",
          field: "temperature",
          sort: "asc",
          width: 270,
        },
        {
          label: "Humidity (%)",
          field: "humidity",
          sort: "asc",
          width: 200,
        },
        {
          label: "Pressure (hPa)",
          field: "pressure",
          sort: "asc",
          width: 200,
        },
        {
          label: "Avg Wind Speed (kph)",
          field: "avgWindSpeed",
          sort: "asc",
          width: 100,
        },
        {
          label: "Max Wind Speed (kph)",
          field: "maxWindSpeed",
          sort: "asc",
          width: 100,
        },
        {
          label: "Angle (°)",
          field: "angle",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: "Wind Direction",
        //   field: "direction",
        //   sort: "asc",
        //   width: 100,
        // },
      ]
      const rows = weatherData?.results?.map(item => {
        return {
          date: item.time?.split("T")[0]?.split("-").reverse().join("-") || "--",
          time: convert24To12(item.time?.split("T")[1].split(".")[0]),
          rainFall: (item.rainfall_24),
          temperature: roundOff(item.temperature),
          humidity: roundOff(item.humidity),
          pressure: roundOff(item.pressure),
          avgWindSpeed: roundOff(convertToKph(item.speed)),
          maxWindSpeed: roundOff(convertToKph(item.speed_max)),
          angle: roundOff(item.direction),
          // direction: getDirection(item.direction),
        }
      })

      setData({
        columns,
        rows,
      })
    }
  }, [isSuccess, weatherData]);

  document.title = "Weather Report | Malankara AWS"


  const handleExportCSV = async (e) => {
    e.preventDefault();
    try {
      let query = {
        limit: printLimit,
        offset: 0,
        deviceId,
        startDate,
        endDate
      };
      
      const response = await exportWeatherDataCSV(query);
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'weather_data.csv';
      
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleExportPdf = async (e) => {
    e.preventDefault();
    try {
      let query = {
        limit: printLimit,
        offset: 0,
        deviceId,
        startDate,
        endDate
      };
      
      const response = await exportWeatherDataPdf(query);
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'weather_data.pdf';
      
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid" style={{backgroundColor:'#E3FDF2'}}>
          <Breadcrumbs
            maintitle="Malankara"
            title="Reports"
            breadcrumbItem="Weather Report"
          />
          <Row>
            <Col md={5}></Col>
            <Col md={7}>
              <div className="d-flex gap-2 align-items-center justify-content-end">
              {/* <FormGroup>
                <Label for="deviceId">Device Id</Label>
                <Input
                  value={deviceId}
                  onChange={e => setDeviceId(e.target.value)}
                  type="select"
                  name="deviceId"
                  id="deviceId">
                  <option value={'DFWSA1015'}>Weather 1</option>
                </Input>
                </FormGroup> */}
                <FormGroup>
                <Label for="printLimit">No of Records</Label>
                <Input
                  value={printLimit}
                  onChange={e => setPrintLimit(e.target.value)}
                  type="select"
                  name="printLimit"
                  id="printLimit">
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={150}>150</option>
                  <option value={200}>200</option>
                  <option value={250}>250</option>
                  <option value={300}>300</option>
                </Input>
              </FormGroup>
                <FormGroup>
                <Label for="startDate">Start Date</Label>
                <Input
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                  type="date"
                  name="startDate"
                  id="startDate">
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">End Date</Label>
              <Input
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                  type="date"
                  name="endDate"
                  id="endDate">
                </Input>
              </FormGroup>

              <Button onClick={handleExportCSV} className="mt-3 bg-primary text-white">
                Export CSV
                </Button>
                <Button onClick={handleExportPdf} className="mt-3 bg-primary text-white">
                Export Pdf
              </Button>
              </div>
              
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Weather Report</CardTitle>
                  <MDBDataTable
                    noBottomColumns
                    deviceIding={false}
                    paging={false}
                    responsive
                    bordered
                    data={data}
                  />
                  <div className="mt-4 d-flex justify-content-end">
                  <PaginationComponent
                    itemsCount={weatherData?.count}
                    itemsPerPage={printLimit}
                    setCurrentPage={setPage}
                    currentPage={page}
                  />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WeatherReport
